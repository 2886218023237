import React from "react"
import Footer from "../../../components/footer"
import Header from "../../../components/header"
import SEO from "../../../components/SEO"
import "../../../styles/ebook-assessment.less"
import { Button } from "../../../components/ui"
const loadText = require("src/utils").loadText
const data = loadText("ebook-assessment")
const ASSESSMENT_URL =
  "https://castelazocontent.outgrow.us/611c6285e09f2a2f5e798dd9"
const TopBanner = () => {
  const { topBanner } = data
  return (
    <div className="topBanner">
      <div className="topBanner-bg">
        <div className="headerContainer ">
          <Header logo="/logo-blue.svg" />
        </div>

        <div className="topBanner-content">
          <div className="left">
            <div className="title">{topBanner.title}</div>
            <div className="subtitle mt-24">{topBanner.subtitle}</div>
            <div className="description mt-24">{topBanner.description}</div>
          </div>
          <div className="right">
            <img src="/ebook-assessment/topBanner-pic.png" alt="ebook" />
          </div>
        </div>
      </div>
    </div>
  )
}

const B1 = () => {
  return (
    <div className="b1">
      <div className="b1-title">{data.b1.title}</div>
      {data.b1.content.map(
        ({ title, subtitle, description, btnText, link }, i) => (
          <div className="b1-item">
            <picture>
              <source
                srcSet={`/ebook-assessment/b1-${i + 1}-m.png 640w `}
                media="(max-width: 640px)"
              />
              <img src={`/ebook-assessment/b1-${i + 1}.png`} alt={title} />
            </picture>
            <div className="right">
              <div className="b1-item-title">{title}</div>
              <div className="b1-item-subtitle">{subtitle}</div>
              <div className="b1-item-description">{description}</div>
              <Button
                width={320}
                className="only-desktop"
                onClick={() => {
                  window.open(ASSESSMENT_URL)
                }}
              >
                {btnText}
              </Button>
              <Button
                width={496}
                className="only-mobile"
                onClick={() => {
                  window.open(ASSESSMENT_URL)
                }}
              >
                {btnText}
              </Button>
            </div>
          </div>
        )
      )}
    </div>
  )
}
export default function Home() {
  return (
    <div className="ebook-assessment">
      <SEO {...data.seo} featuredImage="/featuredImage/zenlayer.jpg" />
      <TopBanner />
      {/* <Content /> */}
      <B1 />
      <Footer />
    </div>
  )
}
